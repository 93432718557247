@import 'app.scss';
.contentHeader {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  align-items: center;
  justify-content: center;
  margin-bottom: 1.25rem;
}

.title {
  margin: 0;
}

.text {
  margin-right: auto;
  margin-left: auto;
  max-width: 75%;
}

.icon {
  min-width: 3rem;
  height: auto;
}

.button {
  margin-top: 1.25rem;
}
