@import 'app.scss';
.banner {
  position: relative;
  z-index: 2;
  background-color: var(--color-yellow-500);
  padding: spacing(1) 0;
  color: var(--color-fg-on-default);
}

.contentContainer {
  display: flex;
  grid-column: 1 / -1;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  max-height: 4rem;
}

.contentHeader {
  display: flex;
  gap: spacing(1);
  align-items: center;
  width: max-content;
  overflow: hidden;

  @include from-breakpoint(md) {
    gap: spacing(2);
  }
}

.icon {
  min-width: 1.25rem;
}

.title,
.text,
.link {
  margin-bottom: 0;
  line-height: 1;
  white-space: nowrap;
  font-size: 1rem;

  @include from-breakpoint(md) {
    line-height: 1.25;
    font-size: 1.25rem;
  }
}

.text {
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;

  @include from-breakpoint(md) {
    display: block;
  }
}

.closeIcon {
  position: relative;
  transition: var(--transition-base);
  z-index: 1;
  margin-left: auto;
  max-width: 2rem;

  &:hover {
    transform: scale(0.9);
    cursor: pointer;
  }
}
