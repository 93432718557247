@import 'app.scss';
@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: center;
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
    transform-origin: center;
  }
}

/**
 * Button
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.button {
  --height: 64px;
  --color: var(--color-fg-on-primary-cta);
  --color-hover: var(--color-fg-on-primary-cta);
  --bg-color: var(--color-bg-primary-cta);
  --bg-color-hover: var(--color-bg-primary-cta-hover);

  @include shared-input;
  @include typography-button;
  @include wave-animation(var(--bg-color-hover));

  display: inline-flex;
  align-items: center;
  border-radius: calc(var(--height) / 2);
  background-color: var(--bg-color);
  min-height: var(--height);
  text-decoration: none;
  color: var(--color);

  &:hover,
  &:focus {
    background-color: var(--bg-color-hover);
    color: var(--color-hover);

    .iconContainer {
      transform: translateX(3px);
    }
  }

  &[disabled],
  [disabled] > & {
    cursor: not-allowed;
  }

  &[disabled] {
    border-color: var(--color-gray-500) !important;
    background-color: var(--color-gray-500) !important;

    &:hover {
      color: var(--color-white) !important;
    }
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  display: flex;
  font-family: var(--font-family-base);
  font-weight: 700;
}

.iconContainer {
  transition: transform var(--transition-duration-base)
    var(--transition-timing-base);
  margin-left: spacing(1);
  text-decoration: none;

  &:first-child {
    margin-right: spacing(1);
    margin-left: 0;
  }
}

.icon {
  width: 1rem;
  height: 1rem;
}

.loadingIcon {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  animation: rotate 0.5s linear 0s infinite forwards;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.button.small,
.button.smallMobile {
  --height: 56px;
  --shared-input-padding-vertical: 0.75rem;
  --shared-input-padding-horizontal: #{spacing(3)};
}

.button.smallMobile {
  padding-right: spacing(4);
  padding-left: spacing(4);

  @include from-breakpoint(lg) {
    --height: 64px;
  }
}

.button.hideLabel {
  justify-content: center;
  box-shadow: none;

  .label {
    @include visually-hidden;
  }

  .iconContainer {
    margin-left: 0;
  }
}

.button.hideLabel,
.button.noAnimation {
  &:hover,
  &:focus {
    .iconContainer {
      transform: none;
    }
  }
}

.button.playButton {
  --bg-color-hover: transparent;

  .icon {
    width: 3.125rem;
    height: 3.125rem;

    @include from-breakpoint(md) {
      width: 5.625rem;
      height: 5.625rem;
    }
  }
}

.button.searchResults {
  box-shadow: 0 4px 8px 0 #0003;
  font-size: 1.25rem;
}

.button.secondary {
  --color: var(--color-fg-on-secondary-cta);
  --color-hover: var(--color-fg-on-secondary-cta);
  --bg-color: var(--color-bg-secondary-cta);
  --bg-color-hover: var(--color-bg-secondary-cta-hover);
}

.button.white {
  --color: var(--color-fg-on-default);
  --color-hover: var(--color-fg-on-default);
  --bg-color: var(--color-bg-default);
  --bg-color-hover: var(--color-bg-primary-cta-hover);
}

.button.loading {
  --color: var(--color-hover);
  --bg-color: var(--bg-color-hover);

  pointer-events: none;

  .label {
    opacity: 0.5;
  }

  .loadingIcon {
    display: block;
  }
}

.button.text {
  --bg-color: transparent;
  --bg-color-hover: transparent;

  @include typography-body;

  border-radius: 0;
  padding: 0;
  min-height: 0;

  &:hover:not(&.noUnderline),
  &:focus:not(&.noUnderline) {
    .label {
      text-decoration: underline;
      text-underline-offset: 0.25em;
    }
  }

  .label {
    font-weight: 400;
  }

  .iconContainer {
    margin-left: spacing(2);

    &:first-child {
      margin-right: spacing(2);
      margin-left: 0;
    }
  }
}

.button.link {
  --bg-color: transparent;
  --bg-color-hover: transparent;

  @include typography-body;

  border-radius: 0;
  padding: 0;
  min-height: 0;

  &:hover,
  &:focus {
    .label {
      text-decoration: none;
    }
  }

  &:focus-visible {
    @include generic-focus;
  }

  .label {
    text-decoration: underline;
    text-underline-offset: 0.25em;
    font-weight: 700;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .iconContainer {
    margin-left: spacing(1);

    &:first-child {
      margin-right: spacing(1);
      margin-left: 0;
    }
  }
}

.button.boldText {
  .label {
    font-weight: 700;
  }
}

.button.largeIcon {
  .icon {
    width: 2rem;
    height: 2rem;
  }

  .iconContainer {
    margin-left: spacing(1);

    &:first-child {
      margin-right: spacing(1);
      margin-left: 0;
    }
  }
}

.button.quickLinksButton {
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow-tile);
  padding: spacing(2) spacing(2) spacing(1);
  width: fit-content;

  .inner {
    flex-direction: column-reverse;
    gap: spacing(2);
  }

  .iconContainer {
    margin-left: 0;
  }

  .icon {
    width: 2.625rem; // 42px
    height: 2.625rem; // 42px
  }

  .label {
    line-height: 120%;
    font-size: 0.875rem;
    font-weight: 900;
  }
}
